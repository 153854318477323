import { useCallback } from "react";

export const CURSOR_MAGNIFIER_EVENT = "CursorMagnifierEvent";

export const useCursorMagnifier = () => {
  const onMouseEnter = useCallback((detail) => {
    window.dispatchEvent(
      new CustomEvent(CURSOR_MAGNIFIER_EVENT, {
        detail: {
          flow: "enter",
          ...(detail || {})
        }
      })
    );
  }, []);

  const onMouseLeave = useCallback((detail) => {
    window.dispatchEvent(
      new CustomEvent(CURSOR_MAGNIFIER_EVENT, {
        detail: {
          flow: "leave",
          ...(detail || {})
        }
      })
    );
  }, []);

  return { onMouseEnter, onMouseLeave };
};
