import React from 'react'
import ReactMarkdown from 'react-markdown'
import { template } from 'lodash'

// https://www.i18next.com/translation-function/plurals
import translations from './translations.json'

// https://github.com/stefangabos/world_countries
import countries from './country-translations.json'

export const KEY_USER_LANGUAGE = 'user-locale'

const availableLanguages = ['fr', 'en']

function getUserLanguage() {
  const navigatorLanguage = navigator.language.slice(0, 2)
  return availableLanguages.includes(navigatorLanguage)
    ? navigatorLanguage
    : 'en'
}

export function getLocale() {
  let locale = localStorage.getItem(KEY_USER_LANGUAGE)
  if (!locale || !availableLanguages.includes(locale)) {
    locale = getUserLanguage()
    localStorage.setItem(KEY_USER_LANGUAGE, locale)
  }
  return locale
}

export function setLocale(locale) {
  const currentLocale = getLocale()
  if (locale !== currentLocale && availableLanguages.includes(locale)) {
    localStorage.setItem(KEY_USER_LANGUAGE, locale)
    window.location.reload()
  }
}

export function getStage() {
  const host = window.location.host.replace('localhost', 'dev')
  const [stage] = host.match(/(dev|staging)/) || []
  return stage || 'prod'
}

export function translate(
  string,
  variables,
  { md = false, plural = false, linkTarget = '_blank' } = {},
) {
  const language = getLocale()
  const number = plural ? 'other' : 'singular'

  let translation = translations.find(
    t => t.fr.other === string
      || t.fr.singular === string
      || t.en.other === string
      || t.en.singular === string,
  )

  if (['dev'].includes(getStage()) && !translation) {
    console.warn('Missing translation: %s', string)
  }

  translation = translation ? translation[language][number] : string

  const compiled = template(translation)(variables)
  return md ? (
    <ReactMarkdown
      source={compiled}
      escapeHtml={false}
      linkTarget={linkTarget}
    />
  ) : (
    compiled
  )
}

export function getCountry(key) {
  const localeIndex = ['en', 'fr'].findIndex(k => k === getLocale())
  const index = countries.findIndex(([en, fr]) => en === key || fr === key)
  return countries[index] ? countries[index][localeIndex] : key
}

export function getCountries() {
  const localeIndex = ['en', 'fr'].findIndex(k => k === getLocale())
  return countries.map(t => [t[0], t[localeIndex], t[2]])
}

export function getCountryISOAlpha2Code(key) {
  const index = countries.findIndex(([en, fr]) => en === key || fr === key)
  return countries[index] ? countries[index][2] : ''
}

export function getCountryByISOAlpha2Code(key, locale) {
  const localeIndex = ['en', 'fr'].findIndex(k => k === locale)
  const index = countries.findIndex(([, , iso]) => iso === key)
  return countries[index] ? countries[index][localeIndex] : ''
}

export function getCountryContinent(key) {
  const index = countries.findIndex(([en, fr]) => en === key || fr === key)
  return countries[index] ? countries[index][3] : ''
}
