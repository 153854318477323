import { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import CircleFollowMouse from "./components/Mouse";
import { ReactComponent as Arrow } from "./svg/arrow.svg";
import { ReactComponent as LinkArrow } from "./svg/link-arrow.svg";
import Text from "./components/Text";
import Timeline from "./components/Timeline";
import { createContext, useReducer } from "react";
// import useSoundCloudData from "./components/useSoundCloudData";
import { translate as t, setLocale, getLocale } from "./helpers/i18n";

export const locale = getLocale();
export const Context = createContext(null)

const reducer = (state, newState) => ({ ...state, ...newState })

const initState = () => {
  return {
    video: '',
    cursor: null,
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, null, initState)

  const [changeLanguage, setChangeLanguage] = useState(locale);
  const [language, setLanguage] = useState(false);

  const handleChange = (input) => {
    setLocale(input);
    // window.location.pathname = getPathnameWithLocale(
      // window.location.pathname,
      // input
    // );
  };

  const refButtonLanguage = useRef(null);
  const refLanguage = useRef(null);

  const handleClickOutside = (event) => {
    if (
      refButtonLanguage.current &&
      !refButtonLanguage.current.contains(event.target) &&
      refLanguage.current &&
      !refLanguage.current.contains(event.target)
    ) {
      setLanguage(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <Wrapper>
        <div>
          <Text>yann</Text>
          <Text>Rostingt</Text>

          <Line></Line>
        </div>
        <Timeline></Timeline>
        <Footer>

          <div>
          <TextWrapper>
            <StyledSpan
              color="rgba(255, 255, 255, 0.5)"
              style={{ display: "flex", alignItems: "center" }}
            >
              Say Hi
              <Arrow style={{ margin: "0 16px" }} />
            </StyledSpan>
          </TextWrapper>
          <LinkWrapper margin="0 24px" href="mailto:rostingtyann@gmail.com">
            <StyledSpan marginRight="15px">Email</StyledSpan>
            <LinkArrow />
          </LinkWrapper>
          </div>
          <LanguageDropDown>
            <LanguageButton
              ref={refButtonLanguage}
              onClick={() => setLanguage(true)}
              type="button"
            >
              {changeLanguage === "fr" ? "EN" : "FR"}
            </LanguageButton>
            {language && (
              <Language ref={refLanguage}>
                <button
                  type="button"
                  onClick={() => {
                    handleChange(changeLanguage === "fr" ? "en" : "fr");
                    setChangeLanguage(changeLanguage === "fr" ? "en" : "fr");
                  }}
                >
                  {t(changeLanguage === "fr" ? "Français" : "Anglais")}
                </button>
              </Language>
            )}
          </LanguageDropDown>
        </Footer>
        <CircleFollowMouse />
      </Wrapper>
    </Context.Provider>
  );
}

const Line = styled.div`
  height: 100%;
  border-radius: 1rem;
  margin-left: 7px;
  width: 2px;
  position: absolute;
  background: ${({ index }) =>
    index % 2 === 0
      ? "linear-gradient(to bottom, #000, #FFF)"
      : "linear-gradient(to bottom, #FFF, #000)"};
`;


const Wrapper = styled.div`
height: auto;
& > div:first-child {
  height: 100vh;
}
`;

const LinkWrapper = styled.a`
  color: ${(props) => props.color || "#fff"};
  text-decoration: none;
  height: 24px;
  margin-left: 15px;
`;

const TextWrapper = styled.p`
  color: ${(props) => props.color || "#fff"};
  height: ${(props) => props.height || "24px"};
  margin: ${(props) => props.margin || "0"};
  line-height: 24px;
  a {
    color: ${(props) => props.color || "#fff"};
  }
`;

const StyledSpan = styled.span`
  line-height: 1.5;
  color: ${(props) => props.color || "#fff"};
  height: 24px;
  margin-right: ${(props) => props.marginRight || "0"};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3rem;
  padding-top: 3rem;
  border: 0 solid rgba(255, 255, 255, 0.2);
  border-top-width: 1px;
  height: 600px;
  & >  div:first-child {
    display: flex;
  }
`;

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const dropdown = css`
  position: relative;
`;
const LanguageDropDown = styled.div`
  z-index: 1000;
  display: flex;
  align-items: center;
  ${dropdown}
`;

const Language = styled.div`
  background-color: ${({ theme }) => theme.second};
  width: 85px;
  height: 45px;
  position: absolute;
  top: 50px;
  right: 0px;
  animation: ${fade} 0.5s;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    border: none;
    background-color: #fff;
    outline: none;
    font-size: 20px;
    padding: 10px;
    color: #000;
  }

  &:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  & > button:hover {
    transition-duration: 0.4s;
    text-decoration: none;
    border-radius: 10px;
  }
`;

const LanguageButton = styled.button`
  margin-right: 10px;
  padding: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  background: transparent !important;
  border: none;
  outline: none;
  color: #fff;

`;

// const chevron = css`
//   & path {
//     stroke: #444444;
//     transition: 0.5s;
//   }
// `;

// const Arrow = styled.span`
//   transition: 0.5s;
//   padding-left: 3px;
//   position: relative;
//   left: 3px;
//   bottom: 2px;
// `;


export default App;
