import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import SplitType from "split-type";
import { useCursorMagnifier } from "./useCursorMagnifier";

const Text = ({ children }) => {
  const textRef = useRef(null);
  const { onMouseEnter, onMouseLeave } = useCursorMagnifier();

  useEffect(() => {
    const myText = new SplitType(textRef.current);

    gsap.to(".char", {
      y: 0,
      stagger: 0.05,
      delay: 0.2,
      duration: 0.1
    });
  }, [children]);

  return (
    <TextWrapper
      ref={textRef}
      onMouseEnter={() => onMouseEnter({ width: "150px", height: "150px" })}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </TextWrapper>
  );
};

const TextWrapper = styled.p`
  @media (max-width: 700px) {
    font-size: 75px;
  }
  @media (max-width: 400px) {
    font-size: 55px;
  }
  font-size: 150px;
  width: unset;
  color: #fff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  a {
    color: #fff;
  }
  & > span {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default Text;
