import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import { useCursorMagnifier } from "./useCursorMagnifier";



const projects = [
  {
    id: 8,
    title: "Full time chez smsmode",
    date: "Sep 2023",
    skills: ["angular", "react", "gsap"],
    description: "Actuellement à plein temps chez smsmode, j'utilise mes compétences pour développer des solutions innovantes dans le monde du messaging."
  },
  {
    id: 7,
    title: "smsmode part time",
    date: "Sep 2022 - Jul 2023",
    description: "Amélioration de l'interface utilisateur de smsmode, intégrant de nouvelles fonctionnalités telles que le rich message.",
    skills: ["angular", "grapesjs"],
  },
  {
    id: 6,
    title: "Learning skydiving",
    date: "Mai 2022",
    video: "/exit.mp4",
    links: "https://www.ffp.asso.fr/methodes-denseignement-et-brevets/",
    description: "Expérience de parachutisme, explorant la sensation paradoxale de tomber tout en étant soutenu par l'air."
  },
  {
    id: 5,
    title: "smsmode internship",
    date: "Avr - Sep 2021",
    skills: ["gatsby", "swagger", 'netlify'],
    link: "https://dev.smsmode.com/",
    description: "Stage de Master II chez smsmode, développant la documentation des APIs REST basée sur Swagger OpenAPI 3.0."
  },
  {
    id: 4,
    title: "Freelance chez DevPepper",
    date: "Oct 2020 - Mar 2021",
    video: "/symble.webm",
    skills: ["react", "aws", "styled-components"],
    description:
      "J'ai travailler a la création d'une plateforme e-commerce permettant aux marques de mettre en vente leur catalogue de produits et de gérer leurs commandes clients"
  },
  {
    id: 3,
    title: "Assistant EPITECH Region",
    date: "Mar - Oct 2020",
    description: "En tant qu'Assistant EPITECH Région, j'ai accompagné des personnes en réinsertion professionnelle dans l'apprentissage du code, en collaboration avec la Web Académie.",
    link: "https://www.webacademie.org/",
  },
  {
    id: 2,
    title: "Transpare",
    date: "Jul - Dec 2019",
    description:
      "Stage de six mois chez Transpare, une start-up contribuant à la création d'une plateforme simplifiant les échanges entre importateurs, exportateurs et transitaires.",
    video: "/transpare.mov",
    skills: ["react", "graphql"]
  },
  {
    id: 1,
    title: "EPITECH, European Institute of Technology",
    date: "Sep 2018",
    description: "Découverte de la programmation en 2018, rejoignant la promotion 2023 après le bac. Diplômé d'EPITECH en 2023."
  }
];


const TimelineItem = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  gap: 20px;
  flex-direction: row-reverse;
  min-height: 450px;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

`;

const TimelineContent = styled.div`
  color: #fff;
  padding: 20px;
  padding-top: 0px;
  height: 100%;
  border-radius: 5px;
  max-width: 550px;
  width: 100%;
  & > * > * {
    margin: 10px 0;
  }
  @media (max-width: 700px) {
    padding: 15px;
  }
  @media (max-width: 400px) {
    font-size: 5px;
  }
`;

const Dot = styled.div`
  position: sticky;
  top: 155px;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0.3em #fff;
`;

const Line = styled.div`
  height: 100%;
  border-radius: 1rem;
  width: 2px;
  background: ${({ index }) =>
    index % 2 === 0
      ? "linear-gradient(to bottom, #000, #FFF)"
      : "linear-gradient(to bottom, #FFF, #000)"};
`;

const Container = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const Timeline = () => {
  const cursorMagnifier = useCursorMagnifier()

  return (
    <div>
      {projects.map((project, index) => (
        <TimelineItem id={project.title} key={project.id}>
          <TimelineContent index={index}>
            <Card project={project} index={index} {...cursorMagnifier} />
          </TimelineContent>
          <Container>
            <Dot />
            <Line index={index} />
          </Container>
        </TimelineItem>
      ))}
    </div>
  );
};

export default Timeline;
