import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { translate as t } from "../helpers/i18n";

gsap.registerPlugin(ScrollTrigger);

const Pre = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  max-width: calc(555px - 4rem);

  & > * {
    /* text-shadow: 0 0 0.3em currentColor; */
  }
  &::before {
    inset: 0.75rem;
    border-radius: inherit;
    background: black;
    z-index: -1;
    transform: translateZ(-50px);
    filter: blur(15px);
    opacity: 0.5;
  }
  &::after {
    z-index: -2;
    inset: -1rem;
  }
  @media (max-width: 700px) {
    font-size: 16px;
    & > h1 {
      font-size: 30px;
    }
  }
  @media (max-width: 400px) {
    font-size: 14px;
    & > h1 {
      font-size: 25px;
    }
  }
`;

const Card = ({ project, index, onMouseEnter, onMouseLeave }) => {
  const preRef = useRef(null);

  useEffect(() => {
    const preElement = preRef.current;
    gsap.fromTo(
      preElement,
      { autoAlpha: 0, y: 100 },
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.5,
        duration: 0.5,
        ease: "power3.out",

        scrollTrigger: {
          trigger: preElement,
          start: "top bottom-=100",
          end: "bottom top+=100",
          scrub: true
        }
      }
    );

    return () => {};
  }, []);

  return (
    <Pre
      ref={preRef}
      tabIndex="0"
      onMouseEnter={(e) => {
        // dispatch({ video: project.video })
        onMouseEnter({ video: project.video });
      }}
      onMouseLeave={(e) => {
        // dispatch({ video: '' })
        onMouseLeave({ video: "" });
      }}
    >
      <p>{project.date}</p>
      <Container>
        <h1>{project.title}</h1>
          {/* {project.video && <LinkArrow />} */}
      </Container>
      <p>{t(project.description)}</p>
      <Link href={project?.link} target="_blank" rel="noopener noreferrer">{project && project.link}</Link>
      <Skills>
        {project?.skills &&
          project?.skills.map((skill) => <Skill>{skill}</Skill>)}
      </Skills>
      {/* {project.video && <Video src={project.video} height={800} width={500}></Video>} */}
      {/* <p>{project.skills}</p> */}
    </Pre>
  );
};


const Container = styled.div`
display: flex;
 align-items: center;
 & > svg {
  transform: rotate(225deg);
  margin-left: 15px;
 }
`

const Link = styled.a`
 color: #fff;
 text-decoration: none;
 outline: none;
 padding: 15px 0;
`;


const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Skill = styled.div`
  border: 1px solid #fff;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 0 0.3em #fff;
  margin: 5px;
`;

export default Card;
