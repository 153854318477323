import React, { useRef, useEffect, useMemo, useContext } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { Context } from "../App";
import { CURSOR_MAGNIFIER_EVENT } from "./useCursorMagnifier";

const Cursor = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff !important;
  opacity: 0;
  position: fixed;
  z-index: 10000;
  user-select: none;
  pointer-events: none;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 0.3em #fff;
  overflow: hidden;
  transition: width 0.7s, height 0.7s, border-radius 0.7s;
`;

const CursorFollower = () => {
  const cursorRef = useRef(null);
  const videoRef = useRef(null);
  const { state, dispatch } = useContext(Context)
  const isTouchDevice = "ontouchstart" in window;
  
  useEffect(() => {
    const handleCursorMagnifierEvent = (e) => {
      const { detail: { flow, video, width: customWidth = '50px', width: customHeight = '50px' } } = e
      const width = video ? 800 : customWidth;
      const height = video ? width / (16 / 9) : customHeight;
      const borderRadius = video ? '2px' : '50%';

      if (cursorRef.current) {
        gsap.to(cursorRef.current, {
          duration: 0.1,
          ease: "power3.out",
          opacity: 1,
          width: flow === 'enter' ? width : "25px",
          height: flow === 'enter' ? height : "25px",
          borderRadius: flow === 'enter' ? borderRadius : "13px",
          onStart: () => {
            if (flow === 'enter' && videoRef.current && video) {
              videoRef.current.src = video;
            }
          },
          onComplete: () => {
            if (flow === 'leave' && videoRef.current) {
              videoRef.current.src = '';
            }
          },
        });
      }
    }

    const handleMouseMoveEvent = (e) => {
      const { clientX, clientY } = e
      if (cursorRef.current) {
        gsap.to(cursorRef.current, {
          x: clientX,
          y: clientY,
          duration: 0.1,
          ease: "power3.out",
          opacity: 1,
        });
      }
    }

    window.addEventListener(CURSOR_MAGNIFIER_EVENT, handleCursorMagnifierEvent)
    window.addEventListener("mousemove", handleMouseMoveEvent)

    return () => {
      window.removeEventListener(CURSOR_MAGNIFIER_EVENT, handleCursorMagnifierEvent)
      window.removeEventListener("mousemove", handleMouseMoveEvent)
    }
  }, [cursorRef, videoRef])


  return isTouchDevice ? null : (
    <Cursor ref={cursorRef}>
      <VideoContainer
        ref={videoRef}
        autoPlay
        loop
        muted
        // width={width}
        // height={height}
        playbackRate={16}
        src={state.video}
      ></VideoContainer>
    </Cursor>
  );
};

const VideoContainer = styled.video`
  object-fit: cover;
  max-width: 800px;
`;
export default CursorFollower;
